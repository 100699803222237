<template>
  <a-row class="call-us">
    <a-col :span="12" :xs="24" :lg="12" class="call-us-l">
      <vue-map></vue-map>
    </a-col>
    <a-col :span="12" :xs="24" :lg="12" class="call-us-r">
      <div class="contactUs">
        <h2>联系我们</h2>
        <ul>
          <li v-for="item in callUsList" :key="item.id">
            <img :src="item.callIcon" />
            <span>{{ item.callPhone }}</span>
          </li>
        </ul>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import VueMap from "../../views/VueMap";
export default {
  components: {
    VueMap,
  },
  data() {
    return {
      // 联系我们数据
      callUsList: [
        {
          callId: 1,
          callIcon: require("../../assets/image/蒙版组 102.png"),
          callPhone: "客服电话：021-54580601",
        },
        {
          callId: 2,
          callIcon: require("../../assets/image/蒙版组 101.png"),
          callPhone: "Sales@keytech-engineering.com",
        },
        {
          callId: 3,
          callIcon: require("../../assets/image/蒙版组 100.png"),
          callPhone: "上海达译机电工程有限公司",
        },
        {
          callId: 4,
          callIcon: require("../../assets/image/蒙版组 99.png"),
          callPhone: "武汉达译自动化工程有限公司",
        },
        {
          callId: 5,
          callIcon: require("../../assets/image/蒙版组 98.png"),
          callPhone: "武汉市东西湖区环湖中路当代硅谷电子产业园 12栋1单元",
        },
      ],
    };
  },
};
</script>

<style lang="less">
.call-us {
  width: 1440px;
  height: 500px;
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;

  .call-us-l {
    height: 100%;
  }

  .call-us-r {
    padding-left: 35px;

    .contactUs {
      h2 {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      padding: 35px;

      ul {
        li {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .call-us {
    width: 1440px;
    height: 500px * 2;
    margin: 0 auto;
    margin-top: 140px;
    margin-bottom: 100px;
    display: block;
    // align-items: center;

    .call-us-l {
      height: 100%;
    }

    .call-us-r {
      text-align: center;
      .contactUs {
        h2 {
          font-size: 40px * 2;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }

        padding: 35px;

        ul {
          li {
            font-size: 18px * 2;
            font-family: Source Han Sans CN;
            font-weight: 400;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}</style>
